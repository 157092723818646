<div class="customer-agent-page-container">
  <div class="inner">
    <div class="image-container">
      @if (imageName !== '') {
        <app-dynamic-image
          [filename]="imageName"
        ></app-dynamic-image>
      }
      @if (imageName === '') {
        <mat-icon
          aria-hidden="false"
          aria-label="Laddar foto"
          >portrait</mat-icon
          >
        }
      </div>
      <div class="text-container">
        @if (
          agentName.toLowerCase() === 'kundcenter' ||
          agentName.toLowerCase() === 'sme') {
          <p
            class="text"
            >
            Är det något du undrar över som rör din energi? Du är alltid välkommen
            att kontakta oss på kundcenter så hjälper vi dig!
          </p>
        } @else {
          <p class="text">
            Är det något du undrar över som rör din energi? Välkommen att kontakta
            mig om du har några frågor!
          </p>
        }
        <div class="contact">
          @if (agentName.toLowerCase() !== 'sme') {
            <p class="text">
              <strong class="tw-capitalize">{{ agentName }}</strong>
            </p>
          }
          <div class="phone info tw-mb-1">
            <img class="icon" src="/assets/images/icons/telefon.png" alt="" />
            <p>
              <a href="tel:{{ phone }}">{{ phone }}</a>
            </p>
          </div>
          <div class="email info">
            <img class="icon" src="/assets/images/icons/form.png" alt="" />
            @if (email) {
              <p>
                <a href="mailto:{{ email }}">Skicka ett mail till mig</a>
              </p>
            }
            @if (!email) {
              <p>
                <a target="_parent" href="{{ form }}">Skicka in ett ärende här</a>
              </p>
            }
          </div>
        </div>
        <p class="text">Följ oss gärna på sociala medier!</p>
        <div class="social">
          <p>
            <a
              href="https://se.linkedin.com/company/kalmar-energi"
              target="_blank"
              >
              <img class="icon" src="/assets/images/icons/linkedin.png" alt="" />
            </a>
          </p>
          <p>
            <a href="https://www.facebook.com/kalmarenergi/" target="_blank">
              <img class="icon" src="/assets/images/icons/facebook.png" alt="" />
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/energirepubliken/" target="_blank">
              <img class="icon" src="/assets/images/icons/instagram.png" alt="" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  